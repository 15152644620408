import { ROUTES } from "const";

export class DIGCOMP {

    static getDashboardCards() {

        return [
            {
                title: 'Bando', link: ROUTES.COURSE_MANIFESTO,
                img: '/img/icon/graduate.png', className: 'image-box'
            }, {
                title: 'Messaggi', link: ROUTES.COURSE_TICKETS,
                img: '/img/icon/message.png', className: 'image-box'
            }, {
                title: 'Situazione pagamenti', link: ROUTES.COURSE_PAYMENTS,
                img: '/img/icon/payment.png', className: 'image-box'
            }, {
                title: 'Fatture', link: ROUTES.COURSE_INVOICES,
                img: '/img/icon/invoice.png', className: 'image-box'
            },
            {
                title: 'Prenotazione esami', link: ROUTES.ICDL_EXAM_RESERVATION,
                img: '/img/icon/7.webp', className: 'image-box'
            }
        ];
    }

    static getDashboardNotices() {
        return [
            {
                title: `Info e istruzioni esame`,
                content: `<a href="https://www.formazionedocenti.it/files/corsi/DIGCOM/info_e_istruzioni_esame.pdf" target="_blank">Clicca qui per scaricare il file</a>`,
            },
            {
                title: `Syllabus DIGCOMP2.2`,
                content: `<a href="https://www.formazionedocenti.it/files/corsi/DIGCOM/syllabus_esame_digcomp.pdf" target="_blank">Clicca qui per scaricare il file</a>`,
            },
            {
                title: `Corso/Manuale di preparazione  per l'esame DIGCOMP2.2`,
                content: `<a href="https://www.formazionedocenti.it/files/corsi/DIGCOM/manuale_di_preparazione_per_l_esame_digcomp2_2.pdf" target="_blank">Clicca qui per scaricare il file</a>`,
            },
        ];
    }
}