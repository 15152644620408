import React from "react";

import {
  MButton2,
  MInput,
  MLabel,
  MSearchUniversity,
  MToggle,
  ValidatedForm,
} from "components/forms";
import { payloadBuilder } from "libs";
import dayjs from "dayjs";

export class FormTitleA extends ValidatedForm {
  constructor(props) {
    super(props);

    // bind event handler to invoke it from child component
    this.onChange = this.onChange.bind(this);
  }

  emptyFields = {
    description: "",
    istitution: "",
    aa: "",
    dataConseguimento: "",
    baseVote: "",
    vote: "",
    lode: false,
    city: "",
    nation: "",
    competitionClass: "",
    denominazione: "",
    telefono: "",
    fax: "",
    email: "",
    pec: "",
  };

  state = {
    showSearchUniversity: false,
    university: {},
    ...this.emptyFields,
    defaultValues: {},
  };

  ERROR_MESSAGES = {
    istitution: "Dato obbligatorio",
    description: "Dato obbligatorio",
    dataConseguimento: "Dato obbligatorio",
    aa: "Inserire l'anno accademico in formato AAAA/AAAA",
    baseVote: "Dato obbligatorio",
    vote: "Dato obbligatorio",
    competitionClass: "Dato obbligatorio",
    nation: "Dato obbligatorio",
    city: "Dato obbligatorio",
  };

  validation = {
    istitution: (value) => value !== "",
    description: (value) => value !== "",
    city: (value) => value !== "",
    dataConseguimento: (value) => this.validateDataConseguimento(value),
    aa: (value) => this.validateAA(value),
    vote: (value) => value !== "" && !isNaN(value) && value > 0,
    baseVote: (value) => value !== "" && !isNaN(value) && value > 0,
    competitionClass: (value) => value !== "",
    nation: (value) => value !== "",
  };

  FormTitleAConfigurator = [
    [
      {
        id: "searchUniversity",
        component: MButton2,
        onClick: (e) => {
          this.searchUniversity(e);
        },
        label: "Cerca Ateneo",
        className: "col-md-12 mb-5 ",
        classElement: "btn btn-primary bg-dark",
      },
    ],
    [
      {
        id: "city",
        field: "city",
        label: "Città Università",
        component: MInput,
        type: "text",
        className: "col-md-6",
        readOnly: true,
      },
      {
        id: "istitution",
        field: "istitution",
        label: "Università",
        component: MInput,
        type: "text",
        className: "col-md-6",
        readOnly: true,
      },
    ],
    [
      {
        id: "vote",
        field: "vote",
        label: "Voto",
        component: MInput,
        type: "text",
        className: "col-md-3",
      },
      {
        id: "baseVote",
        field: "baseVote",
        label: "Base Voto",
        component: MInput,
        type: "text",
        className: "col-md-3",
      },
      {
        id: "lode",
        field: "lode",
        label: "Lode",
        component: MToggle,
        className: "col-md-2",
      },
    ],
    [
      {
        id: "aa",
        field: "aa",
        label: "Anno Accademico conseguimento Titolo",
        component: MInput,
        infoText: "inserire formato AAAA/AAAA",
        className: "col-md-4",
      },
      {
        id: "dataConseguimento",
        field: "dataConseguimento",
        label: "Data conseguimento titolo",
        component: MInput,
        type: "date",
        className: "col-md-4",
      },
      {
        id: "competitionClass",
        field: "competitionClass",
        label: "Classe di concorso",
        infoText: "Indicare la classe di concorso",
        component: MInput,
        className: "col-md-4",
      },
    ],
    [
      {
        field: "preferExamPresence",
        label: "Informazioni facoltative:",
        infoText:
          "Informazioni per la verifica dei dati ex articolo 15, comma 1, punto e della legge 183/2011. Recapiti della segreteria competente.",
        component: MLabel,
        className: "col-md-12",
      },
    ],
    [
      {
        id: "denominazione",
        field: "denominazione",
        label: "Denomianzione",
        component: MInput,
        type: "text",
        className: "col-md-12",
      },
    ],
    [
      {
        id: "telefono",
        field: "telefono",
        label: "Telefono",
        component: MInput,
        type: "text",
        className: "col-md-4",
      },
      {
        id: "fax",
        field: "fax",
        label: "Fax",
        component: MInput,
        type: "text",
        className: "col-md-4",
      },
      {
        id: "email",
        field: "email",
        label: "Email",
        component: MInput,
        type: "text",
        className: "col-md-4",
      },
    ],
    [
      {
        id: "pec",
        field: "pec",
        label: "Pec",
        component: MInput,
        type: "text",
        className: "col-md-6",
      },
    ],
  ];

  //--- ---

  validateAA = (value) => {
    let formAA = /^(\d{4})\/(\d{4})$/.test(value);

    if (!formAA) {
      return false;
    } else {
      
      formAA = value.split("/");
      var dbAA = [];

      if (parseInt(formAA[1]) === parseInt(formAA[0]) + 1) {

        //--- ---
        if (this.props.item.aaStart !== 'null') { //nell'anno aa
          dbAA = this.props.item.aaStart.split("/");
          if(parseInt(formAA[1]) !== parseInt(dbAA[1])){
            console.log("anno AA uguale");
            return false;
          }

          return true;
        }

        //--- ---
        if (this.props.item.aaEnd !== 'null') { //entro l'anno aa
          dbAA = this.props.item.aaEnd.split("/");
          if(parseInt(formAA[1]) > parseInt(dbAA[1])){
            console.log("anno AA minore");
            return false;
          }

          return true;
        }

      } else {
        return false;
      }
    }
  }; //validateAA

  validateDataConseguimento = (value) => {
    if (value === "") {
      return false;
    }
    let regex = /^\d{4}-\d{2}-\d{2}$/;

    if (!value.match(regex)) {
      return false;
    }

    //--- ---
    if (this.props.item.achievementDateStart !== null) {
      const formDate = new Date(value);
      const Dbdate = new Date(dayjs(this.props.item.achievementDateStart).format("YYYY-MM-DD"));

      if (formDate < Dbdate) {
        console.log("data minore");
        return false;
      }

      return true;
    }

    //--- ---
    if (this.props.item.achievementDateEnd !== null) {
      const formDate = new Date(value);
      const Dbdate = new Date(dayjs(this.props.item.achievementDateEnd).format("YYYY-MM-DD"));

      if (formDate > Dbdate) {
        console.log("data maggiore");
        return false;
      }

      return true;
    }
  };

  /*componentDidMount() {
    const defaultValues = this.FormTitleAConfigurator.getDefaultValues();
    this.setState({ defaultValues: defaultValues });
  }*/

  getPayload = () => {
    /*
    this.payload = payloadBuilder(this.state);
    this.payload.qualificationType = this.props.qualificationType;
    this.payload.template = this.props.template;
    this.payload.extCode = FormTitleBConfigurator.getExtCode(
      this.props.template
    );
    if (!this.payload.nation) {
      this.payload.nation = "Italia";
    }

    if (this.state.university && this.state.university.uniCode) {
      this.payload.uniCode = this.state.university.uniCode;
      this.payload.uniCode = this.state.university.uniCode;
    } else if (this.state.school && this.state.school.schoolCode) {
      this.payload.uniCode = this.state.school.schoolCode;
    }

    return this.payload;
    */
  };

  searchUniversity = () => {
    this.setState({ showSearchUniversity: !this.state.showSearchUniversity });
  };

  onChangeUniversity = (university) => {
    this.onChange("city", university.city);
    this.onChange("istitution", university.istitution);
    this.setState({
      showSearchUniversity: false,
      university: university,
    });
  };

  getFields(template, onSelectedUniversity) {
    /*const fieldGroup = this[template];
    if (fieldGroup) {
      fieldGroup[0][0].onClick = onSelectedUniversity;
    } else {
      console.error(template + " not found");
    }*/
    return template;
  }

  //--- ---

  render() {
    const { defaultValues, showSearchUniversity } = this.state;

    const fields = this.getFields(
      this.FormTitleAConfigurator,
      this.searchUniversity
    );
    const { code } = this.renderFields(fields, defaultValues, true);

    return (
      <>
        {showSearchUniversity && (
          <>
            <MSearchUniversity
              onChange={this.onChangeUniversity}
              searchUniversity={this.searchUniversity}
            />
          </>
        )}

        {!showSearchUniversity && <form className="user mt-5">{code}</form>}
      </>
    );
  }
}
