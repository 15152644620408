import React, { Component } from 'react';

import { Title, MSection } from 'components/misc';
import {
    Row, Col,
    Card, CardBody,
    CardTitle, CardText, Accordion, AccordionHeader,
    AccordionBody
} from 'design-react-kit';
import { ContactBox } from 'components/layout';
import { ROUTES } from 'const';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

export class PageContact extends Component {

    contacts = [
        {
            title: 'MILANO',
            img: '/img/city/milano.webp',
            r1: 'Piazza Firenze - 14',
            r2: '20154, Milano',
            tel: '0234593068',
            fax: '0234532652',
            link: ROUTES.PAGE_CONTACT_INFO + '/milano'
        },
        {
            title: 'ROMA',
            img: '/img/city/roma.webp',
            r1: 'Via Properzio - 6',
            r2: '00193, Roma',
            tel: '0670495308',
            fax: '0677456950',
            link: ROUTES.PAGE_CONTACT_INFO + '/roma'
        },
        {
            title: 'FIRENZE',
            img: '/img/city/firenze.webp',
            r1: 'Via di Novoli, 37',
            r2: '50127, Firenze',
            tel: '055485899',
            fax: '0554626203',
            link: ROUTES.PAGE_CONTACT_INFO + '/firenze'
        },
        {
            title: 'BOLOGNA',
            img: '/img/city/bologna.webp',
            r1: 'Viale della Repubblica - 3/A',
            r2: '40127, Bologna',
            tel: '0516335062',
            fax: '0516336563',
            link: ROUTES.PAGE_CONTACT_INFO + '/bologna'
        },
        {
            title: 'PADOVA',
            img: '/img/city/padova.webp',
            r1: 'Via Andrea Costa - 19',
            r2: '35124, Padova',
            tel: '049680148',
            fax: '0498675003',
            link: ROUTES.PAGE_CONTACT_INFO + '/padova'
        },
        {
            title: 'SANTA MARGHERITA DI BELICE (AG)',
            img: '/img/city/santa_margherita.webp',
            r1: 'Largo Monfalcone - 15',
            r2: '92018, Santa Margherita di Belice',
            tel: '092533231',
            tel2: '092531499',
            phone: '3296553069',
            fax: '092532708',
            link: ROUTES.PAGE_CONTACT_INFO + '/santa-margherita-di-belice'
        }
    ];

    defaultState = {
        collapseOpen1: false,
        collapseOpen2: false,
        collapseOpen3: false,
        collapseOpen4: false,
        collapseOpen5: false,
        collapseOpen6: false,
        collapseOpen7: false,
        collapseOpen8: false,
        collapseOpen9: false,
        collapseOpen10: false,
        collapseOpen11: false,
    }

    state = {
        ...this.defaultState,
    }


    toggle = id => {
        this.setState({
            ...this.defaultState,
            [`collapseOpen${id}`]: !this.state[`collapseOpen${id}`]
        })
    }

    render() {

        const { collapseOpen1, collapseOpen2, collapseOpen3, collapseOpen4,
            collapseOpen5, collapseOpen6, collapseOpen7, collapseOpen8, collapseOpen9, collapseOpen10, collapseOpen11 } = this.state;

        const title = 'SEDI REGIONALI';
        return (
            <>
                <Helmet>
                    <title>{title}</title>
                    <meta property="og:title" content={title} />
                </Helmet>
                <Title>{title}</Title>
                <MSection className='mb-4'>
                    <h6>Orari di apertura uffici: da Lunedì a Venerdì dalle ore 9:00 alle ore 13:00 e dalle ore 14:30 alle ore 18:00</h6>
                </MSection>
                <section className="mb-5 clearfix">
                    <Row>
                        {this.contacts.map((item, i) => (
                            <Col lg={4} md={4} sm={6} xs={12} key={`item-${i}`}>
                                <article className="card-wrapper card-space m-1">
                                    <Card noWrapper className="card-bg rounded shadow">
                                        <div className="img-responsive-wrapper">
                                            <div className="img-responsive">
                                                <figure className="img-wrapper">
                                                    <img
                                                        alt={item.title}
                                                        src={item.img}
                                                        title={item.title}
                                                    />
                                                </figure>
                                            </div>
                                        </div>
                                        <CardBody>
                                            <CardTitle className="text-dark" tag="h5">{item.title}</CardTitle>
                                            <hr className="line-green" />
                                            <CardText className="box-content-left">
                                                {item.r1}<br />
                                                {item.r2}<br />
                                                tel. <a className="text-dark" href={`tel:${item.tel}`}><b> {item.tel}</b></a> {item.tel2 ? <a className="text-dark" href={`tel:${item.tel2}`}><b>- {item.tel2}</b></a> : null} {item.phone ? <a className="text-dark" href={`tel:${item.phone}`}><b>- {item.phone}</b></a> : null}<br />
                                                fax. <a className="text-dark" href={`tel:${item.fax}`}><b>{item.fax}</b></a><br />
                                                <Link to={item.link} className="mt-3 btn btn-small btn-outline-primary">Come <br />raggiungere</Link>
                                            </CardText>
                                        </CardBody>
                                    </Card>
                                </article>
                            </Col>
                        ))}
                    </Row>
                    <Row></Row>
                </section>
                <MSection className="my-4">
                    <Title>SEDI PERIFERICHE</Title>
                    <Accordion>
                        <AccordionHeader active={collapseOpen1} onToggle={() => this.toggle(1)}>
                            <span className="text-green" >LOMBARDIA</span>
                        </AccordionHeader>
                        <AccordionBody active={collapseOpen1}>
                            <p><strong>MILANO
                            </strong> Piazza Firenze - 14 Cap 20154 Tel <a href="tel:0234593068">0234593068</a> Fax 0234532652</p>
                        </AccordionBody>

                        <AccordionHeader active={collapseOpen2} onToggle={() => this.toggle(2)}>
                            <span className="text-green" >VENETO</span>
                        </AccordionHeader>
                        <AccordionBody active={collapseOpen2}>
                            <p><strong>PADOVA </strong>
                                Prof. Luca Del Gaizo - Via Galileo Galilei n.117 - Cap 35020 Albignasego (PD) - Tel. <a href="tel:3289611572">3289611572</a></p>
                            {/*<p><strong>VICENZA </strong>
                                Prof. Giuseppe Marotta Tel. <a href="tel:3396338190">3396338190</a></p>*/}
                            <p><strong>VENEZIA E PADOVA </strong>
                                Dott. Leonardo Ivan Chiarello - Tel. <a href="tel:3299733115">3299733115</a>
                                <a href="mailto:leonardochiarello@hotmail.com">leonardochiarello@hotmail.com</a></p>

                        </AccordionBody>

                        {/*<AccordionHeader active={collapseOpen3} onToggle={() => this.toggle(3)}>
                            <span className="text-green" >FRIULI-VENEZIA GIULIA</span>
                        </AccordionHeader>
                        <AccordionBody active={collapseOpen3}>
                            <p><strong>UDINE </strong>Centro Studi Excol Via T.Ciconi, 22 - Tel.: <a href="tel:0432237462">0432 237462</a> Fax: 0432 234836</p>
                            </AccordionBody>*/}

                        <AccordionHeader active={collapseOpen4} onToggle={() => this.toggle(4)}>
                            <span className="text-green" >TOSCANA</span>
                        </AccordionHeader>
                        <AccordionBody active={collapseOpen4}>
                            <p><strong>FIRENZE </strong>Dott. Nicola Tufaro - Tel. <a href="tel:3930635555">3930635555</a></p>
                        </AccordionBody>

                        <AccordionHeader active={collapseOpen5} onToggle={() => this.toggle(5)}>
                            <span className="text-green" >LAZIO</span>
                        </AccordionHeader>
                        <AccordionBody active={collapseOpen5}>
                            <p><strong>ROMA </strong>Via Properzio - 6 Cap 00193 Tel <a href="tel:0670495308">0670495308</a> Fax 0677456950</p>
                        </AccordionBody>

                        <AccordionHeader active={collapseOpen6} onToggle={() => this.toggle(6)}>
                            <span className="text-green" >CAMPANIA</span>
                        </AccordionHeader>
                        <AccordionBody active={collapseOpen6}>
                            <p><strong>NAPOLI </strong>Europa Futura, via Omodeo n.45 - Napoli Tel. <a href="tel:0815603398">0815603398</a> - <a href="tel:3249880268">3249880268</a></p>
                        </AccordionBody>

                        <AccordionHeader active={collapseOpen8} onToggle={() => this.toggle(8)}>
                            <span className="text-green" >BASILICATA</span>
                        </AccordionHeader>
                        <AccordionBody active={collapseOpen8}>
                            <p><strong>POTENZA </strong>Centro Studi D. Alighieri Srls - Via Vaccaro 41 85100 Potenza. Prof.ssa Giuzio Carolina Tel: <a href="tel:09711566678">0971 156 6678</a></p>
                            <p><strong>MATERA </strong>Dott. Domenico Casamassima Tel. <a href="tel:3407063859">3407063859</a> oppure Tel. <a href="tel:3289630938">3289630938</a> Riceve su appuntamento a Matera in Via Galileo Galilei 1</p>
                        </AccordionBody>

                        {/*<AccordionHeader active={collapseOpen9} onToggle={() => this.toggle(9)}>
                            <span className="text-green" >CALABRIA</span>
                        </AccordionHeader>
                        <AccordionBody active={collapseOpen9}>
                            <p><strong>REGGIO CALABRIA </strong>Università per Stranieri “Dante Alighieri” Via del Torrione n. 95. Ricevimento istanze da lunedì a venerdì dalle ore 10:00 alle ore 13:00 e mercoledì dalle ore 15:00 alle ore 17:00. Rivolgersi al Sig. Antonino Russo o al Sig. Giovanni William Pennestri</p>
                            <p><strong>VIBO VALENTIA (VV) </strong>Istituto Magistrale “V. Capialbi” Via Santa Rubia, 29 Vibo Valentia</p>
                            <p><strong>COSENZA </strong>Prof.ssa Maria Lauretana De Vita</p>
                    </AccordionBody>*/}

                        <AccordionHeader active={collapseOpen10} onToggle={() => this.toggle(10)}>
                            <span className="text-green" >SICILIA</span>
                        </AccordionHeader>
                        <AccordionBody active={collapseOpen10}>
                            {/*<p><strong>MENFI (AG) </strong>Ing. Giovanni Palumbo Email : <a href="mailto:giovannipalumbo@hotmail.it">giovannipalumbo@hotmail.it</a></p>*/}
                            {/*<p><strong>SCIACCA (AG) </strong>Euroformazione Via del sole 15/A Tel. <a href="tel:092582820">092582820</a></p>*/}
                            <p><strong>PALERMO </strong></p>
                            <ul>
                                <li>I.T.C. Paritario “ J. Maritain “ Via Imperatore Federico, 46 - 90143 Tel.  <a href="tel:091363511">091363511</a> Fax. 091363144</li>
                                <li>Faulisi Rosalia Via Sciuti 77/F Tel. <a href="tel:091308927">091308927</a> oppure Viale Strasburgo 404/406v Tel. <a href="tel:0916175426">0916175426</a> Cell. <a href="tel:3284616865">3284616865</a></li>
                                <li>Dott. Leonardo Ivan Chiarello Cell. <a href="tel:3299733115">3299733115</a> Email: <a href="mailto:leonardochiarello@hotmail.com">leonardochiarello@hotmail.com</a></li>
                            </ul>
                            <p><strong>GIULIANA (PA) </strong>Prof. Antonio Cinquemani Via A. Moro Tel. <a href="tel:3771166747">3771166747</a></p>
                            <p><strong>CATANIA </strong>Ist. Tecnico Commerciale “Stesicoro” Via Madonna di Fatima, 53 (traversa di Via Passo Gravina)
                                Gravina di Catania Tel. <a href="tel:0954190052">0954190052</a></p>
                            <p><strong>MESSINA </strong>Studio Prof. Franchina Viale S. Martino, 146 - 98123 Messina Cell. <a href="tel:3351049633">3351049633</a>
                                Tel. <a href="tel:0906510699">0906510699</a></p>
                            <p><strong>MESSINA, UCRIA (ME) </strong> Ins. Gullotti Tel. <a href="tel:3208910426">3208910426</a></p>
                            <p><strong>PALMA DI MONTECHIARO (AG) </strong>Prof. Angelo Amato Via Togliatti II Traversa n°5 - 92020
                                Cell. <a href="tel:3395417356">3395417356</a> - <a href="tel:3921919063">3921919063</a></p>
                            {/*<p><strong>CALTANISSETTA </strong>Prof. Calascibetta Vito Viale Trieste 245 Tel. <a href="tel:3289530852">3289530852</a></p>*/}
                        </AccordionBody>

                        <AccordionHeader active={collapseOpen11} onToggle={() => this.toggle(11)}>
                            <span className="text-green" >SARDEGNA</span>
                        </AccordionHeader>
                        <AccordionBody active={collapseOpen11}>
                            <p><strong>ORISTANO </strong>Ins. Fulvia Tolu Tel. <a href="tel:3336209723">3336209723</a></p>
                        </AccordionBody>


                    </Accordion>
                </MSection>
                <ContactBox />
            </>);
    }
}