
import React, { Component } from "react";
import { Row, Col, Button, Callout } from "design-react-kit";

import { courseService, learningService, networkErrorHelper } from "libs";
import ReactHtmlParser from 'react-html-parser';

import {
    MCheckbox
} from "components/forms";
import { CourseShopper } from "components/enrollment";
import { BoxDanger, MnemoLoading, SupportoTecnico } from "components/misc";
import { Helmet } from "react-helmet";

export class BrowseSingleCourse extends Component {

    REGGIO = 'REGGIO';
    REGGIO_CODE = 'CSUREGGIO';
    UNICAMILLUS = 'UNICAMILLUS';
    UNICAMILLUS_CODE = 'CSUUNICAMILLUS';

    importToPay = 35;
    importBollo = 16;
    importSegreteria = 50;

    state = {
        loading: true,
        loadingError: false,
        courseReggio: null,
        courseUnicamillus: null,
        courses: [],
        coursesSubcribeReggio: [],
        coursesSubcribeUnicamillus: [],
        isValidUnicamillus: '',
        isValidReggio: '',
        currentEnrollment: '',
        hasMasterPromo: false
    }

    loadRemote() {

        courseService.getCourseAllSingleModules()
            .then(({ data }) => {
                this.setState({
                    courses: data.payload,
                });
            })
            .catch(error => {
                console.log(error);
            });

        // load courseContainer from BE
        const currentAA = parseInt(process.env.REACT_APP_AA_YEAR);

        courseService
            .getByCodeAndAA(this.REGGIO_CODE, currentAA)
            .then(({ data }) => {

                const courseReggio = data.payload.course;
                this.setState({
                    courseReggio,
                    loadingError: false
                });
            })
            .catch(error => {

                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                } else {
                    this.setState({ loadingError: true });
                }

                this.setState({ loading: false });
            });

        courseService
            .getByCodeAndAA(this.UNICAMILLUS_CODE, currentAA)
            .then(({ data }) => {

                const courseUnicamillus = data.payload.course;
                this.setState({
                    courseUnicamillus,
                    loading: false,
                    loadingError: false
                });
            })
            .catch(error => {

                if (!networkErrorHelper.is404(error)) {
                    networkErrorHelper.notify(error);
                } else {
                    this.setState({ loadingError: true });
                }

                this.setState({ loading: false });
            });

        // ask to BE if has a master promo
        /** disable it 
        learningService.hasMasterPromo()
            .then(({ data }) => {                
                this.setState({
                    hasMasterPromo: data.payload.hasMasterPromo,
                });
            })
            .catch(error => {
                this.setState({
                    hasMasterPromo: false,
                });
            });
             */
    }

    componentDidMount() {
        this.loadRemote();
    }

    getCFUSubscribed(coursesSubcribe) {
        // check max 60 CFU
        let cfu = 0;
        this.state.courses.forEach(course => {
            if (coursesSubcribe.includes(course.id)) {
                cfu += parseInt(course.cfu);
            }
        });

        return cfu;
    }

    removeSubscribe = (courseId) => {

        //verify if selected course is Reggio or unicamillus 
        const coursesSubcribeReggio = [...this.state.coursesSubcribeReggio]; // make a separate copy of the array
        if (coursesSubcribeReggio.indexOf(courseId) !== -1) {
            const index = coursesSubcribeReggio.indexOf(courseId)
            if (index !== -1) {
                coursesSubcribeReggio.splice(index, 1);
                this.setState({ coursesSubcribeReggio });
            }
        }

        const coursesSubcribeUnicamillus = [...this.state.coursesSubcribeUnicamillus]; // make a separate copy of the array
        if (coursesSubcribeUnicamillus.indexOf(courseId) !== -1) {
            const index = coursesSubcribeUnicamillus.indexOf(courseId)
            if (index !== -1) {
                coursesSubcribeUnicamillus.splice(index, 1);
                this.setState({ coursesSubcribeUnicamillus });
            }
        }

        this.updateInfo(coursesSubcribeReggio, coursesSubcribeUnicamillus);
    }

    updateInfo(coursesSubcribeReggio, coursesSubcribeUnicamillus) {

        let infoUnicamillus = '';
        let cfuUnicamillus = 0;
        let isValidUnicamillus = true;

        let infoUniReggio = '';
        let cfuReggio = 0;
        let isValidReggio = true;

        this.state.courses.forEach(course => {
            if (coursesSubcribeUnicamillus.includes(course.id)) {
                cfuUnicamillus += parseInt(course.cfu);
            }
            if (coursesSubcribeReggio.includes(course.id)) {
                cfuReggio += parseInt(course.cfu);
            }
        });

        if (cfuUnicamillus > 0) {
            const className = cfuUnicamillus > 60 ? 'text-danger' : 'text-green';
            infoUnicamillus = '<p class="' + className + '">Hai scelto i seguenti corsi per un totale di ' + cfuUnicamillus + ' CFU. ';
            infoUnicamillus += cfuUnicamillus > 60 ? 'Puoi iscriverti al massimo a 60 CFU.' : '</p>';
            isValidUnicamillus = cfuUnicamillus <= 60;
        }

        if (cfuReggio > 0) {
            const className = cfuReggio > 60 ? 'text-danger' : 'text-green';
            infoUniReggio = '<p class="' + className + '">Hai scelto i seguenti corsi per un totale di ' + cfuReggio + ' CFU';
            infoUniReggio += cfuReggio > 60 ? 'Puoi iscriverti al massimo a 60 CFU.' : '</p>';
            isValidReggio = cfuReggio <= 60;
        }


        this.setState({ isValidUnicamillus, labelInfoUnicamillus: infoUnicamillus, isValidReggio, labelInfoReggio: infoUniReggio, currentEnrollment: '' });
    }

    onClick = (id, value) => {

        if (value) {

            // verify if the value is an id of a course reggio or unicamillus 
            const course = this.state.courses.find(course => course.id === id);
            if (course && course.company === 'UNICAMILLUS') {

                // add course
                const newSubcribe = this.state.coursesSubcribeUnicamillus.concat([id]);
                this.setState({ coursesSubcribeUnicamillus: newSubcribe });
                this.updateInfo(this.state.coursesSubcribeReggio, newSubcribe);
            }

            if (course && course.company === 'REGGIO') {

                // add course
                const newSubcribe = this.state.coursesSubcribeReggio.concat([id]);
                this.setState({ coursesSubcribeReggio: newSubcribe });
                this.updateInfo(newSubcribe, this.state.coursesSubcribeUnicamillus);
            }
        } else {
            this.removeSubscribe(value);
        }
    }

    startEnrollment = (company) => {

        this.setState({ currentEnrollment: company });
    }

    render() {

        const { isLoggedIn } = this.props;
        const { loading, loadingError, courses,
            coursesSubcribeUnicamillus, labelInfoUnicamillus, isValidUnicamillus, courseUnicamillus,
            coursesSubcribeReggio, labelInfoReggio, isValidReggio, courseReggio,
            currentEnrollment, hasMasterPromo
        } = this.state;

        if (loading) return (<MnemoLoading></MnemoLoading>);
        if (loadingError) return (<BoxDanger>Si è verificato un errore durante
            il caricamento dei dati del corso, se il problema persiste puoi <SupportoTecnico /></BoxDanger>);

        // get the list of object coursesSubcribe
        const pluginConfigReggio = courses.filter(course => coursesSubcribeReggio.includes(course.id));
        const pluginConfigUnicamillus = courses.filter(course => coursesSubcribeUnicamillus.includes(course.id));

        const title = "Corsi singoli - Prova finale di profitto da Aprile/Maggio 2025";

        return (
            <>
                <div>
                    <Helmet>
                        <title>{title}</title>
                        <meta property="og:title" content={title} />
                    </Helmet>
                </div>
                <h3 className="p-3">{ReactHtmlParser(title)}</h3>
                <div className="m-3 mt-5 m-lg-5 m-sm-3">
                    <>{ReactHtmlParser(courseUnicamillus.summary)}</>
                </div>
                <hr />
                <div className="m-3 mt-5 m-lg-5 m-sm-3 text-center">
                    <h3>Corsi singoli universitari proposti</h3>
                </div>
                <div className='card-wrapper m-lg-5'>

                    <table className="MTable table  table-striped table-hover" role="table">
                        <thead>
                            <tr >
                                <th>Titolo</th>
                                <th>SSD</th>
                                <th >CFU</th>
                            </tr>
                        </thead>
                        <tbody>
                            {courses.map((course, index) => (
                                <tr key={index} >
                                    <td>
                                        <MCheckbox
                                            className='-ml-10'
                                            value={coursesSubcribeUnicamillus.includes(course.id) || coursesSubcribeReggio.includes(course.id)}
                                            onChange={this.onClick}
                                            id={course.id}>{course.title}
                                        </MCheckbox>
                                    </td>
                                    <td >{course.ssd}</td>
                                    <td>{course.cfu}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="m-3 m-lg-5">
                    <Row>
                        {
                            /* show the list of the coursesSubcribe Unicamillus */
                            coursesSubcribeUnicamillus.length > 0 && (
                                <Col md={6}>
                                    <Callout>
                                        {labelInfoUnicamillus && (<div className="mnemo-callout-title" dangerouslySetInnerHTML={{ __html: labelInfoUnicamillus }} />)}
                                        <hr />
                                        <div >
                                            <table>
                                                <tbody>
                                                    {courses.map((course, index) => (
                                                        coursesSubcribeUnicamillus.includes(course.id) && (
                                                            <tr key={index}>
                                                                <td> {course.title}</td>
                                                                <td className='px-3'>{course.ssd}</td>
                                                                <td >{course.cfu} CFU</td>
                                                                <td><Button color="danger" className="mx-3 my-1" size="xs" outline onClick={e => this.removeSubscribe(course.id)}>Elimina</Button> </td>
                                                            </tr>
                                                        )
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        {(isValidUnicamillus && currentEnrollment !== this.UNICAMILLUS) ?
                                            (<Button color="primary" className="mx-3 my-1" size="xs" onClick={e => this.startEnrollment(this.UNICAMILLUS)}>Avvia iscrizione</Button>)
                                            :
                                            (<Button color="primary" disabled className="mx-3 my-1" size="xs" >Avvia iscrizione</Button>)}
                                    </Callout>
                                </Col>
                            )
                        }
                        {
                            /* show the list of the coursesSubcribe  Reggio */
                            coursesSubcribeReggio.length > 0 && (
                                <Col md={6}>
                                    <Callout  >
                                        {labelInfoReggio && (<div className="mnemo-callout-title" dangerouslySetInnerHTML={{ __html: labelInfoReggio }} />)}
                                        <hr />
                                        <div >
                                            <table>
                                                <tbody>
                                                    {courses.map((course, index) => (
                                                        coursesSubcribeReggio.includes(course.id) && (
                                                            <tr key={index}>
                                                                <td> {course.title}</td>
                                                                <td className='px-3'>{course.ssd}</td>
                                                                <td >{course.cfu} CFU</td>
                                                                <td><Button color="danger" className="mx-3 my-1" size="xs" outline onClick={e => this.removeSubscribe(course.id)}>Elimina</Button> </td>
                                                            </tr>
                                                        )
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                        <hr />
                                        {(isValidReggio && currentEnrollment !== this.REGGIO) ?
                                            (<Button color="primary" className="mx-3 my-1" size="xs" onClick={e => this.startEnrollment(this.REGGIO)}>Avvia iscrizione</Button>)
                                            :
                                            (<Button color="primary" disabled className="mx-3 my-1" size="xs" >Avvia iscrizione</Button>)}
                                    </Callout>
                                </Col>
                            )
                        }
                    </Row>
                    {(currentEnrollment && currentEnrollment === this.REGGIO) &&
                        (
                            <CourseShopper
                                isLoggedIn={isLoggedIn}
                                enrollManifesto={courseReggio.enrollManifesto}
                                courseCode={courseReggio.code}
                                plugin="RSCUCOMBO"
                                pluginConfig={pluginConfigReggio}
                            >
                            </CourseShopper>
                        )
                    }
                    {
                        (currentEnrollment && currentEnrollment === this.UNICAMILLUS) &&
                        (
                            <CourseShopper
                                isLoggedIn={isLoggedIn}
                                enrollManifesto={courseUnicamillus.enrollManifesto}
                                courseCode={courseUnicamillus.code}
                                plugin="USCUCOMBO"
                                pluginConfig={pluginConfigUnicamillus}
                                hasMasterPromo={hasMasterPromo}
                            >
                            </CourseShopper>
                        )
                    }
                </div>
            </>
        );
    }
}