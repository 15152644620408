import { MCheckbox, MLabel, MSelect, ValidatedForm } from "components/forms";
import {
  dateHelper,
  enrollmentAdminService,
  enrollmentService,
  networkErrorHelper,
} from "libs";
import React from "react";
import { FormTitleA } from "./enroll60CFU";
import { Col, Row } from "design-react-kit";

export class EnrollFillForm60CFU extends ValidatedForm {

  constructor(props) {
    super(props);
    // bind event handler to invoke it from child component
    this.onChange = this.onChange.bind(this);
  }
  
  emptyFields = {
    examSite: 0,
  };

  state = {
    _formRefTitleA: null,
    loading: true,
    ...this.emptyFields,
    defaultValues: {
      ...this.emptyFields,
    },
    titleA: "",
    titleAActive: [],
    validationSummary: "",
    currentCompetitionClass:
      this.props.enrollData.pluginConfig.competitionClass || "",
  };

  PAYLOADS = {
    examSites: [],
    titleSelect: [],
  };

  FIELDS_GROUP = [
    [
      {
        field: "preferExamPresence",
        label: "1. SEDE ESAMI",
        infoText:
          "Seleziona una sede convenzionata o in fase di accreditamento",
        component: MLabel,
        className: "col-md-6 pt-3",
      },
    ],
    [
      {
        field: "examSite",
        payload: { options: this.PAYLOADS.examSites },
        component: MSelect,
        className: "col-md-6",
      },
    ],
    /*[
      {
        field: "titleSelect",
        payload: { options: this.PAYLOADS.titleSelect },
        component: MSelect,
        onChange: (_, value) => this.onChangeTitleA(_, value),
        className: "col-md-12",
      },
    ],*/
  ];

  ERROR_MESSAGES = {
    examSite: "Specificare una sede esami",
    titleSelect: "Specificare un requisito di accesso",
  };

  validation = {
    examSite: (value) => value > "",
    titleSelect: (value) => value > "",
  };

  loadRemote(additionalState = {}) {
    const { idExamSession, registrationCode } = this.props.enrollData;
    const { currentCompetitionClass } = this.state;

    /** get all remote qualificationTitleEntry by competitionClass */
    enrollmentService
      .getQualificationTitleEntry(currentCompetitionClass)
      .then(({ data }) => {
        const tmpArr = data.payload.titles;

        for (let i = 0; i < tmpArr.length; i++) {
          let tmpTitle = tmpArr[i].title;
        
          if (tmpArr[i].achievementDateStart !== null) {
            tmpTitle += " - conseguita dopo il " + dateHelper.toITDate(tmpArr[i].achievementDateStart);
          }
          if (tmpArr[i].achievementDateEnd !== null) {
            tmpTitle += " - conseguita entro il " + dateHelper.toITDate(tmpArr[i].achievementDateEnd);
          }
          if (tmpArr[i].aaStart !== 'null') {
            tmpTitle += " - conseguita nell'anno Accademico " + tmpArr[i].aaStart;
          }
          if (tmpArr[i].aaEnd !== 'null') {
            tmpTitle += " - conseguita entro l'anno Accademico " + tmpArr[i].aaEnd;
          }
        
          this.PAYLOADS.titleSelect.push({
            label: tmpTitle,
            value: tmpArr[i].id,
            achievementDateStart: tmpArr[i].achievementDateStart,
            achievementDateEnd: tmpArr[i].achievementDateEnd,
            aaStart: tmpArr[i].aaStart,
            aaEnd: tmpArr[i].aaEnd
          });
        }

        console.log(this.PAYLOADS.titleSelect);
        
        

        if (this.PAYLOADS.examSites.length < 1) {
          // load paylods to fill the select options
          enrollmentAdminService
            .myExamSites(idExamSession, registrationCode)
            .then(({ data }) => {
              const { payload } = data;
              const sites = payload.map(({ value }) => ({
                label: value,
                value: value,
              }));

              this.PAYLOADS.examSites.unshift({
                label: "Seleziona",
                value: "",
              });
              this.PAYLOADS.examSites.push(...sites);

              this.setState({
                examSites: { options: this.PAYLOADS.examSites },
              });
            })
            .catch((errors) => {
              console.log(errors);
              networkErrorHelper.notify(errors);
            });
        }

        const newState = {
          loading: false,
          ...additionalState,
          _formRefTitleA: React.createRef(),
          titleSelect: { options: this.PAYLOADS.titleSelect },
        };

        this.setState(newState);
      })
      .catch((errors) => {
        console.log(errors);
        networkErrorHelper.notify(errors);
      });
  }

  onChangeTitleAActive = (id, value) => {
    const { titleAActive } = this.state;

    if (value) {
      if (!titleAActive.includes(id)) {
        titleAActive.push(id);
      }
    } else {
      const index = titleAActive.indexOf(id);
      if (index > -1) {
        titleAActive.splice(index, 1);
      }
    }

    this.setState({ titleAActive: titleAActive });
  };

  //--- ---

  render() {
    const { loading, defaultValues, _formRefTitleA, validationSummary } =
      this.state;
    if (loading) return <></>;
    const { code } = this.renderFields(this.FIELDS_GROUP, defaultValues, true);

    return (
      <>
        <div>
          <form>{code}</form>
        </div>

        <Row>
          <Col>
            <h6>2. TITOLO DI ACCESSO</h6>
            <p>
              <b>Clicca sul titolo di accesso e Inserisci i dati</b>
              <br />
              lorem ipsum:
            </p>
            {(this.PAYLOADS.titleSelect || []).map((item, idx) => {
              return (
                <div key={`titleA=${item.value.toString()}`}>
                  <MCheckbox
                    onChange={this.onChangeTitleAActive}
                    id={item.value.toString()}
                    name={item.value.toString()}
                    readOnly={false}
                    disabled={false}>
                    <p>
                      <b> {item.label}</b>
                    </p>
                  </MCheckbox>
                  {this.state.titleAActive.includes(item.value.toString()) && (
                    <div className="m-4 p-3 border border-secondary rounded-lg">
                      <FormTitleA
                        key={`titleA=${item.value.toString()}`}
                        qualificationType={item.label}
                        template={item.value.toString()}
                        item={item}
                        ref={_formRefTitleA[item.value.toString()]}
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </Col>
        </Row>
      </>
    );
  }
}
