import { RC30_EDITION, ROUTES } from "const";
import { learningLiveService, courseService, examService } from "libs";

export class RC30 {

  static async getDashboardCards(registrationCode, idExamSession) {
    let cards = this.getFixedCards(idExamSession);

    try {
      const remoteResult = await learningLiveService.isOpenClassBooking(
        registrationCode
      );

      examService
        .getExamByRCode(registrationCode)
        .then((data) => {
          const { exam } = data.data.payload;
          if (exam) {
            if (idExamSession !== RC30_EDITION.second_2024) {
              if (exam.status !== 'revisioned') {
                cards.push({
                  title: "Lezioni",
                  link: ROUTES.COURSE_LESSONLIVE_SCHEDULE_ONLINE,
                  img: "/img/icon/presentation.png",
                  className: "image-box",
                });
              }
            }
          }
        })
        .catch((errors) => {
          if (idExamSession !== RC30_EDITION.second_2024) {
            cards.push({
              title: "Lezioni",
              link: ROUTES.COURSE_LESSONLIVE_SCHEDULE_ONLINE,
              img: "/img/icon/presentation.png",
              className: "image-box",
            });
          }
        });

      const isBookingOpen =
        remoteResult &&
        remoteResult.data &&
        remoteResult.data.payload &&
        remoteResult.data.payload.length > 0;

      // verify dynamic links
      const result = await courseService.perfezionamentoActiveLink(
        registrationCode
      );
      if (result.data.payload) {
        const { payload } = result.data;

        if (isBookingOpen && payload.lessonLiveClassEnabled === "1") {
          cards.push({
            title: "Assegnazione Aula",
            link: ROUTES.COURSE_BOOKING_CLASS,
            img: "/img/icon/student.png",
            className: "image-box",
          });
        }

        if (payload.examReservationEnabled === "1") {
          cards.push({
            title: "Prenotazione prova finale",
            link: ROUTES.COURSE_RC30_EXAM_RESERVATION,
            img: "/img/icon/badge.png",
            className: "image-box",
          });
        }

        if (payload.activeSurvey && payload.activeSurvey.length > 0) {
          cards.push({
            title: "Indagine " + payload.activeSurvey,
            link: ROUTES.COURSE_SURVEY_MAIN + "/" + payload.activeSurvey,
            img: "/img/icon/call.png",
            className: "image-box",
          });
        }

        if (payload.activeSurvey && payload.activeSurvey.length > 0) {
          cards.push({
            title: "Indagine " + payload.activeSurvey,
            link: ROUTES.COURSE_SURVEY_MAIN + "/" + payload.activeSurvey,
            img: "/img/icon/call.png",
            className: "image-box",
          });
        }

        if (payload.SelfDeclarationEnabled === "1") {
          cards.push({
            title: "Autocertificazione d'iscrizione",
            link: ROUTES.USER_SELF_DECLARATION,
            img: "/img/icon/badge.png",
            className: "image-box",
          });
        }

        /** esame scritto */
        if (payload.enableUDA === "1") {
          cards.push({
            title: "Prova scritta",
            link: ROUTES.COURSE_RC30_UDA_EXAMWRITTEN,
            img: "/img/icon/examdone.png",
            className: "image-box",
          });
        }

        if (payload.enableExamSubject === "1") {
          cards.push({
            title: "Argomento Lezione Simulata",
            link: ROUTES.COURSE_RC30_EXAM_SUBJECT,
            img: "/img/icon/7.png",
            className: "image-box",
          });
        }

        if (payload.certRequestEnabled === "1") {
          cards.push({
            title: "Consultazione Esito Finale",
            link: ROUTES.COURSE_RC30_CERT_REQUEST,
            img: "/img/icon/1.png",
            className: "image-box",
          });
        }
      }
    } catch (error) { }

    return cards;
  }

  static getFixedCards(idExamSession) {
    let cards = [
      {
        title: "Messaggi",
        link: ROUTES.COURSE_TICKETS,
        img: "/img/icon/message.png",
        className: "image-box",
      },
      {
        title: "Situazione pagamenti",
        link: ROUTES.COURSE_PAYMENTS,
        img: "/img/icon/payment.png",
        className: "image-box",
      },
    ];

    if (idExamSession === RC30_EDITION.first_2024) {
      cards.push({
        title:
          "Conferma o modifica la sede d'esame scelta all'atto dell'iscrizione",
        link: ROUTES.COURSE_RC30_CONFIRM_EXAMSITE,
        img: "/img/icon/geo.png",
        className: "image-box",
      });
    }

    return cards;
  }

  static getDashboardNotices(idExamSession, aa) {
    if (aa === 2024) {
      if (idExamSession !== RC30_EDITION.second_2024 && idExamSession === RC30_EDITION.first_2024) {
        return [
          {
            title: `Linee guida svolgimento prova finale`,
            content: `<ul>
          <li>Sintesi svolgimento prova finale: <a href="https://www.formazionedocenti.it/files/corsi/RC30/sintesi_svolgimento_prova_finale.pdf" target="_blank">Clicca qui per scaricare l'informativa</a></li>
          <li>Modalità di svolgimento prova finale: <a href="https://www.formazionedocenti.it/files/corsi/RC30/modalita_svolgimento_prova_finale.pdf" target="_blank">Clicca qui per scaricare l'informativa</a></li>
          <li>Modello lezione simulata: <a href="https://www.formazionedocenti.it/files/corsi/RC30/modello_lezione_simulata_rc30.docx">Clicca qui per scaricare l'informativa</a></li>
          </ul>`,
          },
          {
            title: `Calendario Corsi Abilitanti 30 CFU (ex art.13 DPCM 4 agosto 2023)`,
            content: `Seconda fase del percorso - <a href="https://www.formazionedocenti.it/files/corsi/RC30/calendario-lezioni-rc30-2024-II.pdf" target="_blank">Clicca qui per scaricare il calendario</a>`,
          },
          {
            title: `Calendario Corsi Abilitanti 30 CFU (ex art.13 DPCM 4 agosto 2023)`,
            content: `Prima fase del percorso - <a href="https://www.formazionedocenti.it/files/corsi/RC30/calendario-lezioni-rc30-2024-I.pdf" target="_blank">Clicca qui per scaricare il calendario</a>`,
          },
          {
            title: `Linee guida svolgimento delle lezioni`,
            content: `<p><a href="https://www.formazionedocenti.it/files/corsi/RC30/linee-guida-lezioni-30cfu-generic.pdf" target="_blank">Clicca qui per scaricare le linee guida</a></p>`,
          },
        ];
      }
      else if (idExamSession !== RC30_EDITION.second_2024) {
        return [
          /*   {
               title: `Linee guida svolgimento prova finale`,
               content: `<ul>
          <li>Sintesi svolgimento prova finale: <a href="https://www.formazionedocenti.it/files/corsi/RC30/sintesi_svolgimento_prova_finale.pdf" target="_blank">Clicca qui per scaricare l'informativa</a></li>
          <li>Modalità di svolgimento prova finale: <a href="https://www.formazionedocenti.it/files/corsi/RC30/modalita_svolgimento_prova_finale.pdf" target="_blank">Clicca qui per scaricare l'informativa</a></li>
          <li>Modello lezione simulata: <a href="https://www.formazionedocenti.it/files/corsi/RC30/modello_lezione_simulata_rc30.docx">Clicca qui per scaricare l'informativa</a></li>
          </ul>`,
             },*/
          {
            title: `Calendario Corsi Abilitanti 30 CFU (ex art.13 DPCM 4 agosto 2023)`,
            content: `Seconda fase del percorso - <a href="https://www.formazionedocenti.it/files/corsi/RC30/calendario-lezioni-rc30-2024-II.pdf" target="_blank">Clicca qui per scaricare il calendario</a>`,
          },
          {
            title: `Calendario Corsi Abilitanti 30 CFU (ex art.13 DPCM 4 agosto 2023)`,
            content: `Prima fase del percorso - <a href="https://www.formazionedocenti.it/files/corsi/RC30/calendario-lezioni-rc30-2024-I.pdf" target="_blank">Clicca qui per scaricare il calendario</a>`,
          },
          {
            title: `Linee guida svolgimento delle lezioni`,
            content: `<p><a href="https://www.formazionedocenti.it/files/corsi/RC30/linee-guida-lezioni-30cfu-generic.pdf" target="_blank">Clicca qui per scaricare le linee guida</a></p>`,
          },
        ];
      }
    } else {
      /*return [
        {
           title: `Linee guida svolgimento prova finale`,
           content: `<ul>
         <li>Sintesi svolgimento prova finale: <a href="https://www.formazionedocenti.it/files/corsi/RC30/sintesi_svolgimento_prova_finale.pdf" target="_blank">Clicca qui per scaricare l'informativa</a></li>
         <li>Modalità di svolgimento prova finale: <a href="https://www.formazionedocenti.it/files/corsi/RC30/modalita_svolgimento_prova_finale.pdf" target="_blank">Clicca qui per scaricare l'informativa</a></li>
         <li>Modello lezione simulata: <a href="https://www.formazionedocenti.it/files/corsi/RC30/modello_lezione_simulata_rc30.docx">Clicca qui per scaricare l'informativa</a></li>
         </ul>`,
        },
      ];*/
    }
  }
}
